<template>
  <b-overlay :show="loading">
    <div class="statistik-blank text-center" style="margin-top: 75px">
      <div class="" v-if="username == user.username">
        <h2 class="text-dark">
          <b> Selamat! </b>
        </h2>
        <span>Kamu telah terdaftar, try out dilaksanakan mulai tanggal</span> <br />
        <span>
          <b> {{ getTanggal(mulai) }} WIB s/d {{ getTanggal(selesai) }} WIB</b></span
        >
        <br />

        <b-button v-if="in_schedule == true" class="mt-2" variant="outline-danger" @click="buka()"> Kerjakan Tes </b-button>

        <b-button class="mt-2" variant="danger" :to="{ name: 'tryout' }"> Kembali </b-button>
      </div>
      <div class="" v-else>
        <h2 class="text-dark">
          <b> Anda Siapa? </b>
        </h2>
        <b-button class="mt-2" variant="danger" :to="{ name: 'tryout' }"> Kembali </b-button>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { BOverlay, BButton, BLink } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BOverlay,
    BButton,
    BLink,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      loading: false,
      username: this.$route.params.username,
      nama_paket: this.$route.params.nama_paket,
      mulai: this.$route.params.mulai,
      selesai: this.$route.params.selesai,
      in_schedule: this.$route.params.in_schedule,
      item: this.$route.params.obj,
    };
  },

  methods: {
    getTanggal(tanggal) {
      const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
      let date = new Date(tanggal);
      let day = date.getDate();
      let month = date.getMonth();
      let year = date.getFullYear();
      let hour = date.getHours();
      let minute = date.getMinutes();
      let cekMinute = date.getMinutes() < 10 ? "0" : "";

      return day + " " + monthNames[month] + " " + year + ", " + hour + ":" + cekMinute + minute;
    },

    buka() {
      this.loading = true;
      if (this.user.detail == null) {
        this.Modal = true;
      } else {
        const params = {
          paket_id: this.item.id,
        };
        this.$store
          .dispatch("rumpun/rumpunPaket", params)
          .then((resRumpun) => {
            const params = {
              paket_id: this.item.id,
            };
            this.$store
              .dispatch("jurusan/jurusanPaket", params)
              .then((resJurusan) => {
                const params = {
                  paket_id: this.item.id,
                  is_official_school: 1,
                };
                this.$store
                  .dispatch("jurusan/jurusanPaket", params)
                  .then((resSekolah) => {
                    this.loading = false;
                    let existRumpun = resRumpun.data.data;
                    let existJurusan = resJurusan.data.data;
                    let existSekolah = resSekolah.data.data;
                    this.$store.commit("tryout/SET_DETAIL", item);
                    if (this.item.rasionalisasi_type == "kedinasan" && existSekolah[0] == null) {
                      this.$router.push({ name: "pilih-sekolah" });
                    } else if (this.item.is_contain_rumpun == 1 && existRumpun[0] == null && existJurusan[0] == null) {
                      this.$router.push({ name: "pilih-rumpun" });
                    } else {
                      this.$router.push({ name: "tryout-detail" });
                    }
                  })
                  .catch((err) => {
                    this.loading = false;
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: `Error `,
                        icon: "XIcon",
                        variant: "danger",
                        text: err.response.data.message,
                      },
                    });
                  });
              })
              .catch((err) => {
                this.loading = false;
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Error `,
                    icon: "XIcon",
                    variant: "danger",
                    text: err.response.data.message,
                  },
                });
              });
          })
          .catch((err) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error `,
                icon: "XIcon",
                variant: "danger",
                text: err.response.data.message,
              },
            });
          });
      }
    },
  },
};
</script>

<style></style>
