var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('div', {
    staticClass: "statistik-blank text-center",
    staticStyle: {
      "margin-top": "75px"
    }
  }, [_vm.username == _vm.user.username ? _c('div', {}, [_c('h2', {
    staticClass: "text-dark"
  }, [_c('b', [_vm._v(" Selamat! ")])]), _c('span', [_vm._v("Kamu telah terdaftar, try out dilaksanakan mulai tanggal")]), _vm._v(" "), _c('br'), _c('span', [_c('b', [_vm._v(" " + _vm._s(_vm.getTanggal(_vm.mulai)) + " WIB s/d " + _vm._s(_vm.getTanggal(_vm.selesai)) + " WIB")])]), _c('br'), _vm.in_schedule == true ? _c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "variant": "outline-danger"
    },
    on: {
      "click": function click($event) {
        return _vm.buka();
      }
    }
  }, [_vm._v(" Kerjakan Tes ")]) : _vm._e(), _c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "variant": "danger",
      "to": {
        name: 'tryout'
      }
    }
  }, [_vm._v(" Kembali ")])], 1) : _c('div', {}, [_c('h2', {
    staticClass: "text-dark"
  }, [_c('b', [_vm._v(" Anda Siapa? ")])]), _c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "variant": "danger",
      "to": {
        name: 'tryout'
      }
    }
  }, [_vm._v(" Kembali ")])], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }